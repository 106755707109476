@font-face {
  font-family: 'RobotoLocal';
  src: url('./fonts/Regular/Roboto-Regular.ttf') format('woff2'),
       url('./fonts/Regular/Roboto-Regular.woff') format('woff'),
       url('./fonts/Regular/Roboto-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'RobotoLocal';
  font-size: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#header {
  background: #004594;
  min-height: 50px;
  overflow: visible;
  padding-top: 1px;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 999;
}

#logo {
  border-bottom: 1px solid #aaa;
  border-right: 1px solid #aaa;
  bottom: 0;
  height: 100px;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 200px;
}

#buttons {
  bottom: 0;
  height: 50px;
  position: absolute;
  right: 0;
  text-align: right;
}

#buttons .ad {
  background: #000;
  color: #fff;
  cursor: pointer;
  display: block;
  float: left;
  font-size: 30px;
  height: 50px;
  line-height: 50px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  width: 80px;
}

#buttons .ad img {
  height: 30px;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  position: absolute;
  top: 50%;
}

#buttons div {
  background: #cc1120;
  float: left;
  height: 50px;
  width: 15px;
}

.navigation {
  background: #004594;
  bottom: 0;
  display: block;
  overflow: hidden;
  padding: 50px 0 0;
  position: fixed;
  top: 50px;
  left: 0;
  width: 200px;
  z-index: 2;
}

main {
  left: 200px;
  right: 0;
  position: absolute;
  height: 100%;
}

main.fullscreen {
  left: 0px;
  transition: left 0.5s ease-in;
}

main.fullscreen > .page {
  padding-bottom: 65px !important;
}

.navigation.fullscreen {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

#header.fullscreen #logo {
  height: 50px;
  width: 100px;
  transition: height 0.5s ease-in, width 0.5s ease-in;
}

.footnavigation {
  opacity: 0;
  background: #004594;
  bottom: 0;
  display: flex;
  overflow: hidden;
  position: fixed;
  width: 100%;
  z-index: 999;
  height: 60px;
  justify-content: flex-start;
  align-items: center;
}

.footnavigation.fullscreen {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.page {
  margin-top: 50px;
  padding: 15px;
  margin-bottom: 0;
}

a.footlinker {
  box-sizing: content-box;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 20px;
  font-weight: 700;
  height: 100%;
  line-height: 45px;
  padding: 16px;
  text-decoration: none;
  text-shadow: 0 1px 0 #000;
}

a.footlinker.highlighted,
a.footimglink.highlighted {
  background: #dce6f2 !important;
  color: #004594 !important;
  text-shadow: 0 1px 0 #fff !important;
}

a.linker {
  box-sizing: content-box;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 20px;
  font-weight: 700;
  height: 50px;
  line-height: 45px;
  padding: 16px 0px 16px 10px;
  text-decoration: none;
  text-shadow: 0 1px 0 #000;
}

a.linker.highlighted,
a.imglink.highlighted {
  background: #dce6f2 !important;
  color: #004594 !important;
  text-shadow: 0 1px 0 #fff !important;
}

label {
  display: block;
  margin: 0 0 .28571429rem 0;
  color: rgba(0,0,0,.87);
  font-size: .92857143em;
  font-weight: 700;
  text-transform: none;
}

.buttonLineWrapper {
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-wrap: wrap;
  margin-bottom: 15px;
  /* align-items: center; */
  /* position: sticky; */
  top: 50px;
  z-index: 3;
  /* background-color: white; */
}

.labelledButtonWrapper {
  text-align: center;
  padding: 0 12px;
  position: relative;
  margin-top: 10px;
}

.labelledButtonLabel {
  font-size: 70%;
  text-transform: uppercase;
  opacity: 0.7;
}

.dimmed.dimmable>.ui.animating.dimmer, .dimmed.dimmable>.ui.visible.dimmer, .ui.active.dimmer {
  margin-top: 0;
}

.rct-sidebar-header {
  background: rgb(1, 69, 148) !important;
}

.rct-label-group {
  background: rgb(1, 69, 148) !important;
  font-weight: bolder !important;
}

.horizontal-capacity {
  width: 100%;
  height: 12px;
  border: 1px solid black;
  position: absolute;
  top: -20px;
  background: #badbff;
  z-index: 9999;
  max-width: 100%;
}

.horizontal-capacity-progress {
  background: #004697;
  position: absolute;
  border: 0px;
  height: 10px;
}

.react-calendar-timeline {
  z-index: 1;
}

.react-calendar-timeline .rct-header .rct-label {
  text-align: left !important;
}

.itemBox {
  background:#e4e4e4;
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  box-shadow: inset 0 0 10px #00000038;
  height: 100%;
  width: 100%;
}

.itemBoxBottomGrouper {
  display: flex;
  justify-content: space-evenly;
}

.itemBoxBottom {
  border-right: 1px solid rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
  flex: 1;
  height: 26%;
  line-height: 15px;
  overflow: hidden;
  display: block;
}

.itemBoxBottom:last-child {
  border-right: none;
  box-sizing: border-box;
}

.itemBoxBottomGrouper {
  height: 26%;
}

.itemBoxBottomGrouper .itemBoxBottom {
  height: 100%;
}

.itemBoxCenter {
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
  font-size: 14px !important;
  height: 37%;
  line-height: 22px;
  overflow: hidden;
  width: 100%;
  font-weight: bolder;
}

.itemBoxTop {
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
  font-size: 15px;
  height: 37%;
  line-height: 23px;
  overflow: hidden;
  overflow: hidden;
  width: 100%;
}

.rct-custom.rct-item-content {
  display: block !important;
  padding: 0px !important;
  color: #000;
  text-align: center;
}

.rct-custom.rct-item {
  /* background: #e4e4e4 !important; */
  /* margin-left: 1px; */
}

.rct-custom.rct-item.rct-day-view {
  transform: scale(0.91);
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
  border-left: 1px solid #bbb;
}

.rct-item.shouldHover:hover {
  min-width: 150px;
  z-index: 100 !important;
  /* box-shadow: 0px 0px 10px #000000b8; */
}

.rct-item.shouldHover.production_takt:hover {
  min-width: 200px;
  box-shadow: 0px 0px 10px #000000b8;
  z-index: 100 !important;
}

.rct-item.shouldHover.production_takt:hover > .item-time {
  display: block;
}

.rct-item.selected > .item-time {
  display: block;
}

.rct-item.resizing.production_takt {
  /* min-width: 300px; */
  box-shadow: 0px 0px 10px #000000b8; 
  z-index: 100 !important;
}

.rct-drag-right {
  border: 0px solid black;
  text-align: center;
}

.item-time {
  display: none;
}

/* .rct-item.shouldHover:hover > .item-time {
  display: block;
}

.rct-item.shouldHover.show-time > .item-time {
  display: block;
} */

.rct-item.resizing.production_takt > .item-time {
  display: block;
} 

.ui.active.button {
  background-color: #808080 !important;
}

.sd_wrapper {
  display: flex;
  width: 100%;
  height: 200px;
  align-items: stretch;
  padding-right: 20px;
}

.sd_silowrapper{
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 140px;
}

.sd_top{
  flex: 3;
  position: relative;
}

.sd_feets{
  border-top: 5px solid #444;
  border-right: 5px solid #444;
  border-left: 5px solid #444;
  margin: 0 2px;
  flex: 1;
  max-height: 60px;
  min-height: 20px;
  border-radius: 4px;
  display: flex; 
  flex-direction: column;
  justify-content: flex-end;
  background: white;
}

.sd_number{
    text-align: center;
    font-size: 18px;
}

.nolabels .sd_silo {
  font-size: 0;
}

.sd_silo{
  position: absolute;
  bottom: 0;
  left: 10%;
  right: 10%;
  border-left: 2px solid #444;
  border-top: 2px solid #444;
  box-sizing: border-box;
  padding-top:5px;
  text-align: center;
  background: #f0f0f0;
  border-right: 2px solid #444;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.sd_silosize {
  position: relative;
  z-index: 20;
}

.sd_filling{
  border-top: 1px solid rgba(0,0,0,0.5);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  /* padding-top: 5px; */
  box-sizing: border-box;
}

.sd_siloname {
  font-size: 13px;
  font-weight: 700;
  padding: 10px;
  white-space: nowrap;
}

.rct-capacity-item.rct-item-content {
  padding: 2px 2px !important;
}

.rct-capacity-item.rct-item-content.rct-item-center-scaled {
  padding: 2px 0px !important;
}

.ui.selection.dropdown.active, .ui.selection.dropdown.visible {
  z-index: 99 !important;
}

.rct-custom-capacity.rct-label-capacity.rct-label {
  background: #f0f0f0 !important;
}

.react-calendar-timeline .rct-header .rct-label {
  /* background: #014594 !important;
  color: white !important;
  font-weight: bolder !important; */
}

.ui.compact.table:not(.MaterialTable) td {
  padding: 0px !important; 
  padding-left: 8px !important;
}

.div-button {
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin: auto 0;
  background: #e0e1e2;
  line-height: 25px;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0px 0px 1px black;
  margin-right: 5px;
}

.div-button > i {
  margin: 0px !important;
}

.ui.form .field {
  margin: 0 !important;
}

.customError > * {
  background-color: #fff6f6;
  border-color: #e0b4b4;
  color: #9f3a38;
  box-shadow: none;
}

.itemBoxBottom,
.itemBoxRight {
  box-sizing: border-box;
  flex: 1;
  height: 26%;
  line-height: 15px;
  overflow: hidden;
  display: block;
}

.itemBoxRight {
  min-width: 16px;
}

.itemBoxBottom.infobutton:not(.nohover), 
.itemBoxRight.infobutton:not(.nohover) {
  background-color: #0145948f !important;
}

.itemBoxBottom:not(.nohover):hover,
.itemBoxRight:not(.nohover):hover {
  background-color: rgba(164, 164, 164, 0.75);
}

.itemBoxBottom:not(.nohover):active,
.itemBoxRight:not(.nohover):active {
  background-color: rgba(99, 99, 99, 0.75);
}

.selected .itemBoxBottom:not(.nohover):hover,
.selected .itemBoxRight:not(.nohover):hover {
  background-color: rgba(115, 150, 204, 0.75);
}

.selected .itemBoxBottom:not(.nohover):active,
.selected .itemBoxRight:not(.nohover):active {
  background-color: rgba(76, 101, 140, 0.75);
}

.filldiv {
  height: 100% !important;
  width: 100% !important;
}

.rct-infolabel {
  min-width: 200px;
}

.productionBoxColorful {
  border-radius: 0 !important;
}

.rct-header {
  overflow: hidden;
}

.selectionBox {
  border-radius: 3px;
  background-color: rgba(67, 105, 241, 0.4);
  border: 1px solid rgba(45, 85, 234, 0.59);
}

.btnGroup {
  padding-right: 12px !important;
  margin-bottom: 8px !important;
}

div:not(.btnGroup) > .buttonPadRight {
  margin-right: 12px !important;
  margin-bottom: 8px !important;
}

div .buttonLine {
  margin-bottom: 15px;
}

td .buttonLine {
  display: flex;
}

.overcapacity {
  background: linear-gradient(270deg, #c81717, #f03f09);
  background-size: 400% 400%;
  
  -webkit-animation: AnimationName 4s ease infinite;
  -moz-animation: AnimationName 4s ease infinite;
  -o-animation: AnimationName 4s ease infinite;
  animation: AnimationName 4s ease infinite;
}

@-webkit-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-o-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes AnimationName { 
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

.rct-capacity-item.rct-item-content div div {
  background-color: rgb(44, 116, 195) !important  ;
}

.rct-capacity-item.rct-item-content div.overcapacity div {
  background: linear-gradient(270deg, #c81717, #f03f09);
  background-size: 400% 400%;
  
  -webkit-animation: AnimationName 4s ease infinite;
  -moz-animation: AnimationName 4s ease infinite;
  -o-animation: AnimationName 4s ease infinite;
  animation: AnimationName 4s ease infinite;
}


table:not(.usersList) .ui.buttons {
  margin-bottom: 8px !important;
}

div.rct-item.selected, div.rct-item.selected .rct-item-content .itemBox {
  background-color: rgba(160, 183, 209, 0.65) !important;
}

.customReactTable .rt-th:focus {
  outline: none !important;
}

.subComponent {
  display: flex;
  -webkit-box-shadow: 0px 3px 8px -5px rgba(0,0,0,0.59);
  -moz-box-shadow: 0px 3px 8px -5px rgba(0,0,0,0.59);
  box-shadow: 0px 3px 8px -5px rgba(0,0,0,0.59);
}

.subComponent .map {
  flex: 8;
}

.subComponent .cycleTimes {
  flex: 3;
  display: flex;
  flex-direction: column;
  border-left: 2px solid #5f5f5f99;
  box-shadow: -11px 0px 14px -7px rgba(0, 0, 0, 0.18);
  -webkit-box-shadow: -11px 0px 14px -7px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: -11px 0px 14px -7px rgba(0, 0, 0, 0.18);
  z-index: 400;
}

.subComponent .cycleTimes .title {
  border-bottom: 2px solid #5f5f5f99;
  padding: 5px;
  margin-bottom: 10px;
  -webkit-box-shadow: 0px 3px 8px -5px rgba(0,0,0,0.59);
  -moz-box-shadow: 0px 3px 8px -5px rgba(0,0,0,0.59);
  box-shadow: 0px 3px 8px -5px rgba(0,0,0,0.59);
}

.subComponent .cycleTimes .title h2 {
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: normal;
}

.subComponent .cycleTime {
  padding: 6px;
  display: flex;
  padding-left: 12px;
  flex-direction: column;
  justify-content: space-between;
}

.subComponent .cycleTime label {
  flex: 2;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: normal;
  margin: 0;
}

/* .subComponent .cycleTime .input {
  flex: 1;
} */

.lkwInfos {
  display: inline-block;
}

.lkwInfo {
  display: inline-block;
}

.lkwInfo label {
  display: block;
}

.lkwInfo .input {
  display: block;
  margin-right: 12px;
}

.startCycleOptimization {
  margin: 0 !important;
} 

.schedulerLine {
  display: flex;
  margin-bottom: 15px;
  justify-content: flex-end;
}

.rt-td span {
  line-height: 24px;
}

.ReactTable .rt-tbody .rt-tr:not(:last-child), 
.ReactTable .rt-tbody .rt-tr-group:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.28) !important;
}

.ReactTable .rt-tbody .rt-td:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.28) !important;
}

.ReactTable .rt-tbody .rt-td {
  display: flex;
  align-items: center;
}

.ReactTable .rt-tbody .rt-td .ui.input {
  flex: 1;
}

.ReactTable .rt-th {
  text-align: left;
}

.ReactTable .leaflet-container {
  flex: 8;
}

.ReactTable .leaflet-routing-container {
  display: none;
}

input[readonly] {
  background-color: #92929229 !important;
}

#legend {
  display: flex;
  justify-content: center;
}

#legend .legendItem {
  display: flex;
  margin: 8px;
}

#legend .legendItem .colorBox{
  min-width: 25px;
  min-height: 12px;
  margin-right: 5px;
  border: 2px solid #444444d4;
}

.flotr-mouse-value {
  padding: 5px !important;
  z-index: 999 !important;
}

div#liteCodes {
    clear: left;
}

.codeItem {
    display: flex;
    margin: 10px 0;
}

.codesConName {
    min-width: 300px;
}

.codesCode {
    min-width: 100px;
}

.codeItemFooter {
    margin-top: 20px;
}

.codeItem.codeItemHeading {
    margin-bottom: 5px;
    font-weight: bold;
}

.mystyle {
  background-color: red;
}

.rct-sidebar-row {
  cursor: pointer;
}

.rct-sidebar-row .div-button:hover {
  background-color: #d0d0d0;
}

.rct-sidebar-row .div-button:active {
  background-color: #adadad;
}

.MaterialTable tr {
  display: flex;
}

.MaterialTable td, .MaterialTable th {
  padding: 6px !important;
  flex: 4;
}

.MaterialTable td .field .field,
.MaterialTable td .field span {
  width: 100% !important;
}

.MaterialTable td form.ui textarea {
  resize: none;
  overflow: hidden auto;
  padding: 4px;
}

.MaterialInfo input, 
.MaterialInfo div[role="combobox"] {
  min-height: 3em !important;
}

.dailyButton {
  flex: 2;
  margin: 4px !important;
  padding: 4px !important;
}

.dailyButton i {
  color: #353535;
}

.rct-label {
  display: flex;
}

.rct-label .rct-label-text {
  flex: 8;
  margin-left: 2px;
}

.realtimeHeader {
  margin: 8px 0 4px 0;
}

.codeList tr {
  margin: 6px;
}

.codeList td {
  padding: 4px;
}

.codeList td:last-of-type {
  font-weight: bold;
  padding: 4px 10px;
}

.rc-time-picker-panel-clear-btn {
  display: none;
}

.rc-time-picker-panel-select {
  overflow-x: hidden;

}

.ui.popup.transition.visible:not(.white_popup) {
  border: none;
  background-color: rgb(224, 225, 226);
  box-shadow: none;
  filter: invert(100%);
}

.ui.popup.transition.visible:not(.white_popup)::before {
  background-color: rgb(224, 225, 226);
  border: none;
  box-shadow: none;
  position: absolute;
  bottom: -4px;
} 

.react-datepicker-popper {
  z-index: 10;
}

.ui.table td.two.wide {
  word-wrap: anywhere;
  word-break: break-all;
}

.ui.table td {
  word-break: break-all;
}

.mixingplantsList,
.materialsList,
.customersList,
.silosList,
.constructionsitesList {
  max-width: 100%;
  table-layout: auto;
}

.usersList {
  max-width: 100%;
  table-layout: fixed;
}

.mixingplantsList tr th,
.materialsList tr th,
.customersList tr th,
.silosList tr th,
.constructionsitesList tr th,
.usersList tr th {
  word-break: break-word !important;
  white-space: normal !important;
  width: 32px !important;
}

.ui.sortable.table tr th.textSort.sorted.descending::after {
  content: "\f15e";
}

.ui.sortable.table tr th.textSort.sorted.ascending::after {
  content: "\f15d";
}

.ui.sortable.table tr th.numberSort.sorted.descending::after {
  content: "\f163";
}

.ui.sortable.table tr th.numberSort.sorted.ascending::after {
  content: "\f162";
}

.datepickerInput {
  font-size: 12px !important;
}

.datepickerInput input {
  padding: .9em !important;
}

.datepickerInput .ui.label {
  padding: 0.4em .8em !important;
  font-size: 1.6em !important;
}

.datepickerInput i {
  margin: 0 !important;
}

.mapLegend {
  z-index: 1000;
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 11;
  background: rgba(255, 255, 255, 0.7);
  padding: 5px;
}

.tableSortingTooltip {
  float: right;
  padding: 4px;
  margin: -25px 0 0 0;
  color: #8c8c8c;
}

.ui.delete-red.button {
  background-color: rgb(183, 38, 38);
  color: white;
}

.ui.delete-red.button:hover {
  background-color: rgb(146, 22, 22);
}

.permissionLabel {
  vertical-align: top;
  padding-right: 40px !important;
}

.permissionCheckboxCell {
  vertical-align: top;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.permissionCheckbox {

}

.READONLY{
  font-style: italic;
}

.BPO{
  font-style: italic;
}

.inlineDiv {
  display: inline-block;
  padding: 15px;
}

.orderexportmodel .ui.form .field {
  margin-bottom: 15px !important;
}

.exportFromWrapper {
  margin-top: 15px;
}